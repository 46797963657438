<template>
  <v-app>
    <div
      style="position: fixed; height: 100vh; width: 100vw; background-color: #003677"
    />
    <v-container
      id="fundo"
      class="p-0 fill-height"
      fluid
    >
      <v-row
        class="fill-height"
        align="center"
        justify="center"
      >
        <v-col
          cols="11"
          sm="8"
          md="5"
          lg="4"
          xl="3"
          style="padding: 0px"
        >
          <v-container :style="$vuetify.breakpoint.xs ? 'padding: 4px' : ''">
            <v-container class="pb-4">
              <v-row
                class="layout-logos-login fill-height px-4 height"
                align="center"
                justify="center"
              >
                <v-img
                  class="logo-form-login"
                  src="@/assets/logo_branca.png"
                  height="200px"
                  contain
                />
              </v-row>
            </v-container>
            <v-alert
              v-if="!errorMessage"
              border="top"
              colored-border
              type="info"
              elevation="2"
            >
              Aguarde um momento, estamos redirecionando você para a página...
            </v-alert>

            <v-alert
              v-if="errorMessage"
              border="right"
              colored-border
              type="error"
              elevation="2"
            >
              {{ errorMessage }} Caso o erro persista, entre em contato com o suporte.
            </v-alert>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>

  export default {
    data () {
      return {
        errorMessage: '',
      }
    },
    computed: {

    },
    created () {
      this.redirect()
    },
    methods: {
      async redirect () {
        const { shortURL } = this.$route.params

        if (!shortURL) {
          this.$router.push({ name: 'Home' })
        }

        try {
          const response = await this.api.getEntidade('shorten', shortURL)

          window.location.replace(response.data.original_url)
        } catch (error) {
          const { data } = error
          this.errorMessage = data.message
          window.Toast.fire({
            icon: 'error',
            title: 'Erro ao redirecionar!',
            text: data.message,
            timer: 5000,
          })
        }
      },
    },
  }
</script>
